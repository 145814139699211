html {
  font-size: 16px;
}

body {
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: $dark-gray;
  font-size: 1rem;
}

body.instagram-body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: $pink;
  text-decoration: none;
  outline: none;

  &:hover {
    color: darken($pink, 15%);
  }
}

hr {
  border: 0;
  border-top: 1px solid $lighter-gray;
  margin-top: 24px;
  margin-bottom: 24px;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

ol li {
  margin-bottom: 1em;
}

.list--unstyled {
  padding-left: 0;
  list-style: none;
}

.mobile {
  h1 {
    display: none;
  }
}

.nowrap {
  white-space: nowrap;
}