html {
  box-sizing: border-box;
  // height: 100%;
  // overflow: hidden;
}

body {
  // height: 100%;
  // overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.app {
  position: relative;
  // width: 100%;
  // height: 100%;
  // overflow-y: scroll;
  // overflow-x: hidden;
}

.main {
  position: relative;
  margin: 0 auto;
  max-width: 768px;
  padding-top: $sidebar-height;
}

  .account .main {
    padding-top: 0;
  }

  .static .main {
    padding-top: $navbar-height;
  }

.mobile {
  .main {
    margin-top: 0;
    padding: 8px 16px;
  }
}

.content--sidebar {
  // padding-bottom: 48px;
  padding-top: 16px;
}

.page {
  position: relative;
  padding: 0 16px 8px;
}

  .page--links {
    padding-bottom: 0;
  }

.instagram-html {
  height: 100%;
}

.instagram-body {
  height: 100%;
}

@media (min-width: 768px) {
  .content--sidebar {
    padding-bottom: 0;
    padding-left: 200px;
    padding-right: 8px;
    // padding-top: 0;
  }

    .account .content--sidebar {
      padding-left: 8px;
    }

  // .page {
  //   padding: 0;
  // }

  .main {
    padding-top: 0;
  }

}