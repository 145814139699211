.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid $light-gray;
  background-color: #fff;
  color: $dark-gray;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    color: $dark-gray;
    background-color: darken(#fff, 10%);
  }

  &[disabled]:hover {
    color: $dark-gray;
    background-color: #fff;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

  .btn--primary,
  .btn-primary {
    border: 1px solid $pink;
    color: #fff;
    background-color: $pink;

    &[disabled]:hover {
      color: #fff;
      background-color: $pink;
    }

    &:hover {
      color: #fff;
      background-color: darken($pink, 10%);
    }
  }

  // .btn--secondary,
  // .btn-secondary {
  //   border: 1px solid $gray;
  //   color: #fff;
  //   background-color: $gray;

  //   &:hover {
  //     color: #fff;
  //     background-color: darken($gray, 10%);
  //   }
  // }

  .btn--link {
    border: 1px solid transparent;
    background-color: transparent;
    color: $pink;

    &:hover {
      color: darken($pink, 15%);
      background-color: transparent;
    }
  }

  .btn__detail-label {
    font-size: 14px;
    margin-left: 32px;
  }

  .fa--danger {
    color: #cc0000;
  }

  .btn--instagram {
    color: #fff;
    background: linear-gradient(135deg, $blue, $pink);
    border: none;
    // border-radius: 30px;
    // height: 60px;
    // padding: 8px 30px;
    // font-size: 29px;
    // font-weight: 500;
    // line-height: 1.3;

    i {
      vertical-align: middle;
      margin-right: 4px
    }

    .label {
      // font-size: 18px;
      vertical-align: middle;
    }

    &:hover,
    &:focus,
    &:visited {
      color: #fff;
      background: linear-gradient(135deg, $blue, $pink);
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .btn--get-started {
    border: none;
    color: #A2419C;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    transition: all 0.15s ease;
    background: #fff;

    &:hover,
    &:focus,
    &:visited {
      color: #A2419C;
      background: #fff;      
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .btn-info {

    color: $dark-gray;
    // border: 1px solid $light-gray;
    // border-radius: 30px;
    // padding: 0px 8px;
    cursor: pointer;

    &:hover {
      color: $pink;
    }
  
  }