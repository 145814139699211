.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

  .badge--primary {
    background-color: $pink;
  }

  .badge__greek {
    display: inline-block;
  }

  .badge__label {
    display: none;
  }

@media (min-width: 768px) {

  .badge__greek {
    display: none;
  }

  .badge__label {
    display: inline-block;
  }
  
}