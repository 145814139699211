.identities-header {
    align-items: center;
    border-bottom: 1px solid $lighter-gray;

    h2 {
        flex-grow: 1;
    }

    .actions {
        margin-bottom: 1.5em;
    }
}



.identity-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $lighter-gray;
    padding: 10px 0 10px 10px;
    font-size: 14px;
}

    .identity-item__avatar {
        border-radius: 50%;
        width: 32px;
        height: 32px;
    }

    .identity-item__details {
        flex-grow: 1;
        padding-left: 10px;
    }

    .identity-item__name {
        color: $gray;
    }

    .identity-item__type {
        font-weight: bold;
    }

@media (min-width: 768px) {
    .identities-header {
        display: flex;

        .actions {
            margin-bottom: 0;
        }
    }
}