.receipts {
  list-style: none;
  margin: 0;
  padding: 0;
}

.receipt {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid $lighter-gray;
}

  .receipt__details {
    flex-grow: 1;
  }

  .receipt__amount {
    font-weight: bold;
  }