.plan__feature-list {
  padding-left: 0;
  list-style: none;
  font-size: 14px;
  font-weight: bold;
}

.plan__feature-list-item {
  margin-bottom: 8px;
  position: relative;
  margin-left: 24px;

  .fa {
    color: $pink;
  }
}

.plan__actions {
  .btn {
    margin-bottom: 0.5em;
  }
}

.pricing {
  text-align: center;
}

  .pricing__list {
    display: block;
    color: $dark-gray;
    max-width: 300px;
    margin: 0 auto;
  }

  .pricing__item {
    background: #fff;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    // padding: 30px 0;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);
  }

    .pricing__item--featured {
      margin: 0;
      padding: 0;
      z-index: $zindex-pricing-featured;
      // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);

      .pricing__action {
        background-color: $pink;
        color: #fff;

        &:hover {
          color: #fff;
          background-color: darken($pink, 10%);
        }
      }

    }

  .pricing__title {
    font-size: 1.5em;
    // font-weight: 500;
    text-align: center;
    padding: 35px 30px 20px;
    margin: 0 0 8px;
    // color: $pink;
    border-bottom: 2px solid #f6f9fc;
    color: $pink;
  }

  .pricing__price {
    text-align: center;
  }

  .pricing__currency,
  .pricing__amount {
    // margin-bottom: 10px;
    font-size: 2em;
    line-height: 60px;
    // letter-spacing: -.05em;
    vertical-align: middle;
    display: inline-block;
  }

  .pricing__period {
    // font-size: 1em;
    font-weight: 500; 
    line-height: 60px;
    vertical-align: middle;
    display: inline-block;
  }

  .pricing__sentence {
    color: $gray;
    // font-size: 1.6em;
    // line-height: 1.4em;
    // padding-top: 3px;
    text-align: center;
  }

  .pricing__feature-list {
    // font-size: 17px;
    // line-height: 26px;
    width: 100%;
    margin: 8px auto 24px;
    max-width: 390px;
    padding: 0 16px 0 40px;
    list-style: none;
    // min-height: 240px;
    flex-grow: 1;
  }

  .pricing__feature {
    margin-bottom: 16px;
    position: relative;
    text-align: left;

    .fa {
      color: $pink;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .pricing__action {
    display: block;
    padding: 16px;
    text-align: center;
    // background-color: $lighter-gray;
    // color: $dark-gray;
    font-size: 17px;
    line-height: 26px;
    font-weight: 600;
    // letter-spacing: .025em;
    border: none;

    // &:hover {
    //   color: $dark-gray;
    //   background-color: darken($lighter-gray, 15%);
    // }

    background-color: $purple;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: darken($purple, 10%);
    }

    &:disabled {
      background-color: $lighter-gray;
      color: $gray;
    }
  }

@media (min-width: 768px) {

  .pricing__list {
    display: flex;
    max-width: none;
  }

  .pricing__item {
    width: 33.3333333333%;
  }

  .pricing__item {
    width: 33.3333333333%;
    
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

}


