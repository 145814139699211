.devise {
  margin: 0 auto;
  max-width: 300px;
  text-align: center;

  label {
    text-align: left;
  }

  .btn {
    display: block;
    width: 100%;
    margin-bottom: 0.5em;
  }

}
