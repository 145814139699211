.overlay {
  position: absolute;
  z-index: $zindex-overlay;
  left: 0;
  top: 0;
}

  .overlay__container {
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .overlay__form {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }

  .overlay__heading {
    margin-top: 0;
  }
