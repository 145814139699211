.pagination {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  display: flex;

  .page-item {
    display: none;
    background: linear-gradient(to bottom, #fff, #f9fafb);
    border: 1px solid #c4cdd5;
    color: #212b35;
    padding: 9px 15px;
    border-radius: 0;

    a {
      color: #212b35;
    }

    &.prev {
      display: block;
    }

    &.next {
      display: block;
    }

    &.disabled {
      background: #f4f6f8;
      color: #919eab;
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &+.page-item {
      border-left-width: 0;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

}