$navbar-height: 56px;
$sidebar-height: 56px;

$lightest-gray: #f5f7f8;
$lighter-gray: #ECF0F1;
$light-gray: #BDC3C7;
$gray: #7F8C8D;
$dark-gray: #2C3E50;

$pink: #E1306C;
$purple: #A2419C;
$blue: #405DE6;

// zindex

$zindex-navbar: 10;
$zindex-welcome-nav: 10;
$zindex-sidebar: 1;
$zindex-popover: 2;

$zindex-media-editor: 20;
$zindex-media-editor__close: 1;

$zindex-update-queue__modal-overlay: 20;

$zindex-sharer: 1;
$zindex-sharer-thumbnail__cancel: 1;

$zindex-uploader__dropzone: 2;
$zindex-uploader__file-input: 3;
$zindex-uploader__insert-button: 1;

$zindex-overlay: 30;

$zindex-toast: 999999;

$zindex-pricing-featured: 1;
