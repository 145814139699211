.order {
  font-size: 14px;
}

  .order__header {
    // display: flex;
  }

  .order__id {
    font-size: 24px;
    display: inline-block;
  }

  .order__date {
    display: inline-block;
    color: #798c9c;
  }

  .order__card {
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
    margin-bottom: 20px;
  }

  .order__card-header {
    padding: 20px 20px 0 20px;
  }

  .order__card-heading {
    font-size: 16px;
    margin: 0;
  }

  .order__card-section {
    padding: 20px;

    &+.order__card-section {
      border-top: 1px solid #dfe4e8;
    }
  }

  .order__card-subheading {
    font-size: 12px;
    margin: 0;
    text-transform: uppercase;
  }

  .order__product {
    border-top: 1px solid #ebeef0;
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }

  .order__product-image {
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .order__product-name {
    margin: 0 5px;
    flex-grow: 1;
  }

  .order__product-amount {
    margin-left: 5px;
  }

