$navbar-height: 56px;
$sidebar-height: 56px;

$lightest-gray: #f5f7f8;
$lighter-gray: #ECF0F1;
$light-gray: #BDC3C7;
$gray: #7F8C8D;
$dark-gray: #2C3E50;

$pink: #E1306C;
$purple: #A2419C;
$blue: #405DE6;

// zindex

$zindex-navbar: 10;
$zindex-welcome-nav: 10;
$zindex-sidebar: 1;
$zindex-popover: 2;

$zindex-media-editor: 20;
$zindex-media-editor__close: 1;

$zindex-update-queue__modal-overlay: 20;

$zindex-sharer: 1;
$zindex-sharer-thumbnail__cancel: 1;

$zindex-uploader__dropzone: 2;
$zindex-uploader__file-input: 3;
$zindex-uploader__insert-button: 1;

$zindex-overlay: 30;

$zindex-toast: 999999;

$zindex-pricing-featured: 1;

.media-column {

  // > a {
  //   position: relative;
  //   display: block;
  //   width: 100%;
  // }

  .media-item {

    .media-meta {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      color: #fff;
      background-color: rgba(0, 53, 105, 0.5);
      visibility: hidden;

      display: flex;
      align-items: center;

      div {
        margin: 0 10px;
      }
    }

    .media-edit {
      position: absolute;
      height: 30px;
      right: 10px;
      top: 10px;
      
      color: #fff;
      
      display: flex;

      a {
        background-color: rgba(0, 53, 105, 0.5);
        border-radius: 4px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-left: 5px;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    .media-link {
      padding: 5px 10px;
      font-size: 12px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #fff;

      i {
        color: #00cc00;
        margin-right: 5px;

        &.fa-exclamation-triangle {
          color: #cc0000;
        }
      }
    }

  }

}

  .media-item__price {
    position: absolute;
    // bottom: 0;
    // left: 0;
    bottom: 4px;
    left: 4px;
    padding: 6px;
    border-radius: 4px;
    // border-top-right-radius: 4px;
    // background-color: #fff;
    background-color: #009cde;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    // color: #2f2f2f;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    // font-style: italic;
  }

  .media-item__product-icon {
    height: 12px;
    // margin-top: -2px;
    display: block;
  }

  .media-item__product-info {
    margin-left: 6px;
    // padding-top: 2px;
    // margin-bottom: -1px;
  }

  .media-item__product-name {
    margin-right: 4px;
  }

  .media-item__product-amount {
    // color: #3897f0;
    color: #fff;
  }

  .media-item__padding {
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
