.update {
  background-color: #fff;
  // border: 1px solid #efefef;
  // border-radius: 3px;
  margin-bottom: 40px;
  // margin-left: -1px;
  // margin-right: -1px;
  position: relative;
  border-bottom: 1px solid $lightest-gray;
}

  .update__header {
    padding: 12px 0 2px;
    color: $gray;
    display: flex;
    align-items: center;
  }

  .update__profile-picture {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 8px;
  }

  .update__username {
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: bold;
    color: $dark-gray;
    margin-right: 8px;
  }

  .update__time {
    flex-shrink: 0;
    flex-grow: 1;
  }

  .update__repost-btn {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 4px 8px;
  }

  .update__body {
    // border-bottom: 1px solid #efefef;
    // border-top: 1px solid #efefef;
    // display: flex;
    // padding: 8px;
    padding-top: 8px;
  }

  .update__caption {
    flex-grow: 1;
    padding-bottom: 8px;
  }

  .update__attachment {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .update__image {
    // max-width: 144px;
    // max-height: 144px;
    border-radius: 8px;
    // border: 1px solid #f4f7f9;
    // background: #fff;
    // background-clip: padding-box;  
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 8px;
  }

  .update__footer {
    padding: 8px 0;
    color: $gray;
  }

.update-nav {
  position: absolute;
  top: 0;
  right: 0;
}

  .update-nav__toggle {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $gray;
    font-size: 12px;

    &:hover {
      color: $dark-gray;
    }
  }

  .update-nav__menu {
    position: absolute;
    right: 8px;
    top: 32px;
    list-style: none;
    padding-left: 0;
    margin: 0;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
    color: $gray;
    border: 1px solid $light-gray;
  }

  .update-nav__link {
    display: flex;
    height: 32px;
    align-items: center;
    text-decoration: none;
    color: $dark-gray;
    padding: 0 8px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: $dark-gray;
      background-color: $lighter-gray;
    }
  }

@media (min-width: 768px) {

  .update {
    border: 1px solid #efefef;
    border-radius: 3px;
  }

    .update__header {
      padding: 12px 8px 2px;
    }

    .update__body {
      display: flex;
      padding: 8px;
    }

    .update__image {
      max-width: 144px;
      max-height: 144px;
      // border-radius: 3px;
      border: 1px solid #f4f7f9;
      background: #fff;
      background-clip: padding-box;  
      margin-bottom: 0;
    }

    .update__caption {
      padding-left: 8px;
    }

    .update__footer {
      padding: 8px;
      text-align: right;
    }

}
