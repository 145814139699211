.btn--paypal {
  background-color: #009cde;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: #009cde;
    color: #fff;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  }

  .paypal-button-logo-paypal {
    flex-grow: 1;
    margin: 0 8px;
  }

  .paypal-button-extra {
    text-align: right;

    .paypal-button-buy-now {
      font-size: 12px;
      font-style: italic;
      // margin-right: 4px;
    }

    .paypal-button-price {
      font-size: 24px;
      font-style: italic;
      font-weight: bold;
    }
  }

  
}



.product-wrapper {
  display: flex;
  flex-grow: 1;
}

.product {
  color: #262626;
  max-width: 100%;
}

  .product__header {
    display: flex;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    border-bottom: 1px solid #efefef;
  }

  .product__header-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
  }

  .product__header-username {
    margin-left: 12px;
    color: #262626;

    &:hover {
      color: #262626;
    }
  }

  .product__image-wrapper {
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product__image {
    display: block;
    width: 100%;
  }

  .product__details {
    padding: 0 16px;
  }

  .product__purchase {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    padding: 16px 0;

    // display: flex;
  }

  .product__purchase-info {
    flex-grow: 1;
    margin-bottom: 8px;
  }

  .product__name {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .product__amount {
    color: #3897f0;
  }

  .product__response {
    font-weight: 600;
  }

  .product__caption {
    padding: 16px 0 8px;
  }

  .product__time {
    display: block;
    font-size: 10px;
    color: #999;
    text-transform: uppercase;
    padding-bottom: 16px;
  }

  .product__media {
    width: 48px;
    height: 48px;
  }

.product-edit-btn {
  position: absolute;
  right: 16px;
}


.product-media {}

  .product-media__info {
    border-bottom: 1px solid rgba(0,0,0,.05);
    overflow: hidden;
    padding-left: 0;
    float: none;
    width: auto;
  }

  .product-media__image {
    float: left;
    flex-shrink: 0;
    flex-grow: 0;
    width: 50px;
    // margin-right: 10px;
    img {
      display: block;
      width: 50px;
      height: auto;
    }
  }

  .product-media__caption {
    overflow: hidden;
    margin-left: 60px;
    padding-top: 24px;
    position: relative;

    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0;
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%;
      margin-top: 0;
    } 
  }

  .product-media__actions {
    font-size: 14px;

    a {
      color: rgba(0,0,0,.44);        
    }
  }

  .product__sold-out {
    background-color: $lightest-gray;
    color: $gray;
    padding: 8px 16px;
    cursor: default;
  }


@media (min-width: 736px) {

  .product-wrapper {
    padding: 40px 20px;
    align-items: center;
    justify-content: center;
    max-width: 935px;
    margin: 0 auto;
  }

  .product {
    position: relative;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    display: flex;
    overflow: hidden;
  }

    .product__image-wrapper {
      margin-right: 335px;
      min-height: 450px;
    }

    .product__header {
      position: absolute;
      top: 0;
      right: 24px;
      width: 287px;
      padding: 20px 0;
    }

    .product__header-avatar {
      width: 40px;
      height: 40px;
    }

    .product__details {
      padding: 0 24px;
      position: absolute;
      right: 0;
      top: 78px;
      width: 335px;
    }

    .product__purchase {
      border-top: none;
    }


}