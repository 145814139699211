.tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  font-size: 14px;
  // overflow-x: auto;
  flex-wrap: wrap;
  border-bottom: 1px solid $lighter-gray;
  padding-bottom: 4px;
}
  .tabs--links {
    margin-bottom: 0;
  }

  .tabs__tab {
    margin-right: 16px;    
  }

    .tabs__tab--desktop-only {
      display: none;
    }

  .tabs__link {
    display: flex;
    text-decoration: none;
    color: $dark-gray;
    // height: 40px;
    align-items: center;
    white-space: nowrap;
    padding: 4px 0 12px;

    .fa {
      color: $gray;
      margin-right: 8px;
    }

    &:hover {
      color: darken($dark-gray, 15%);

      .fa {
        color: $dark-gray;
      }
    }
  }

    .tabs__link--active,
    .tabs__link--active:hover {
      color: $pink;

      .fa {
        color: $pink;
      }
    }

@media (min-width: 768px) {
  .tabs__tab--desktop-only {
    display: inherit;
  }
}