.payment-row {
  display: flex;

  .form-group {
    width: 100%;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.StripeElement {
  border: 1px solid #BDC3C7;
  border-radius: 2px;
  box-shadow: none;
}