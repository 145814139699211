label {
  display: block;
  font-size: 12px;
  color: $dark-gray;
  margin-bottom: 8px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="search"] {
  display: block;
  width: 100%;
  padding: 8px 8px;
  color: $dark-gray;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid $light-gray;
  border-radius: 2px;
  // margin-bottom: 16px;

  &:disabled {
    color: $light-gray;
    border-color: $lighter-gray;
  }


}

.form-text {
  display: block;
  margin-top: 8px;
  color: $gray;
}

.checkbox {
  font-size: inherit;
}

.radio {
  padding-left: 1.25rem;
  
  input[type="radio"] {
    margin-left: -1.25rem;
    position: absolute;
  }
}

.form-group {
  margin-bottom: 24px;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  // .form-group {
  //   display: flex;
  //   flex: 0 0 auto;
  //   flex-flow: row wrap;
  //   align-items: center;
  //   margin-bottom: 0;
  // }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="number"],
  input[type="search"] {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.divider {
  border-top: 1px solid #DFE0E6;
  display: block;
  line-height: 1px;
  margin: 30px 0;
  position: relative;
  text-align: center;
}

  .divider__title {
    background: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0 20px;
  }

#error_explanation {
  text-align: left;
  background-color: $lightest-gray;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 1em;

  h2 {
    font-size: 16px;
    margin-top: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    font-size: 14px;
  }

  li {
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
}
