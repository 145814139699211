.link-page {
  background-color: #fafafa;
  color: #000;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

  .link-page__info {
    padding: 12px 20px;
    text-align: center;
  }

.profile {
  // margin: 0 20px;
}

  .profile__container {
    margin: 0 auto;
    // max-width: 1446px;
    max-width: 935px;
    display: flex;
    padding: 30px 20px 24px;
    border-bottom: 1px solid #dbdbdb;
  }

  .profile__picture {
    margin-right: 28px;
    flex-shrink: 0;
    background-color: #fafafa;
    border: 1px solid #dbdbdb;
    height: 77px;
    // margin-bottom: -6px;
    // margin-top: -6px;
    width: 77px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
  }

  .profile__details {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
  }

  .profile__username {
    font-size: 21px;
    font-weight: 300;
    color: #262626;
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 21px;
  }

  .profile__full-name {
    // display: inline;
    margin-top: 0;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .profile__link,
  .profile__link:hover {
    font-size: 14px;
    font-weight: 600;
    color: #003569;

    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;

  }

.media-grid {
  padding-bottom: 20px;

  &.editable {
    .media-grid__column {
      width: 100%;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}

  .media-grid__row {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .media-grid__column {
    position: relative;
    display: block;
    margin-right: 3px;
    // margin-bottom: 3px;
    width: 0;
    flex-grow: 1;
    max-width: 480px;

    &:last-child {
      margin-right: 0;
    }
  }

.media-item {
  position: relative;
  display: block;
  width: 100%;
  background-color: #edeeee;
  cursor: pointer;
}

  .media-item__image {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .media-item__link {
    // display: block;
    // width: 100%;
    // padding-bottom: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &:hover {
      background-color: rgba(0,0,0,0.2);
    }
  }

.media-item {
  &.inactive {

    .media-item__image {
      opacity: 0.25;

      &:hover {
        opacity: 0.5;
      }
    }

    .media-item__link {
      i {
        color: #cc0000;
      }
    }
  }
}

// .pagination {
//   padding: 0 20px 20px;
// }

.btn.btn--load-more {
  width: 100%;
  border: 1px solid #3897f0;
  color: #fff;
  background-color: #3897f0;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 600;
  line-height: 32px;
  padding: 0 48px;
  // margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;

  &:hover {
    color: #fff;
    background-color: #3897f0;
  }

  &:active {
    opacity: .7;
  }
}

.powered-by {
  padding: 0 0 20px;
  text-align: center;
}

  .powered-by__link {
    display: inline-block;
    margin: 0 auto;
  }

  .pwered-by__image {
    display: block;
  }

@media (min-width: 768px) {

  .media-grid {
    &.editable {
      
      .media-grid__row {
        flex-wrap: nowrap;
      }

      .media-grid__column {
        width: 0;
        margin-right: 3px;
        margin-bottom: 3px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .btn.btn--load-more {
    margin-left: 0;
    margin-right: 0;
  }

}
