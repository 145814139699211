table {
  border-collapse: collapse; 
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: $gray;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1em;
  background-color: #fff;

  th,
  td {
    padding: 8px;
    vertical-align: top;
    border-top: 1px solid $lighter-gray;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * 1px) solid $lighter-gray;
  }

  tbody + tbody {
    border-top: (2 * 1px) solid $lighter-gray;
  }

  .table {
    background-color: #fff;
  }
}

  .table--small {
    font-size: 12px;
  }

.table-wrapper {
  width: 1px;
  max-width: 100%;
  min-width: 100%;
  overflow-x: auto;
}

.currency {
  text-align: right;
}

