.welcome {}

  .welcome__main {
    position: relative;
    // overflow: hidden;
    display: block;
  }

.welcome-nav {
  position: absolute;
  z-index: $zindex-welcome-nav;
  left: 0;
  top: 0;
  right: 0;
}
  .welcome-nav--light {
    border-bottom: 1px solid $lighter-gray;
    position: fixed;
    background-color: #fff;
  }

  .welcome-nav__container {
    margin: 0 auto;
    width: 100%;
    padding: 0 16px;
    max-width: 768px;
  }

  .welcome-nav__links {
    position: relative;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
  }

  .welcome-nav__item {
    height: 56px;
    display: none;
    align-items: center;
  }

    .welcome-nav__item--logo {
      position: absolute;
      left: 0;
      display: flex;

      a:hover {
        opacity: 0.5;
      }
    }

    .welcome-nav__item--mobile {
      display: flex;
    }

  .welcome-nav__link {
    color: #fff;
    margin-right: 16px;
    display: inline-block;

    img {
      display: block;
    }

    &:hover {
      color: hsla(0, 0%, 100%, 0.5);
    }
  }

    .welcome-nav__link--light {
      color: $pink;

      &:hover {
        color: darken($pink, 15%);
      }
    }

  .welcome-nav__button {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      // color: hsla(0, 0%, 100%, 0.5);
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

    .welcome-nav__button--light {
      border-color: $pink;
      color: $pink;

      &:hover {
        color: darken($pink, 15%);
      }
    }

.mobile {
  .welcome-nav {
    display: none;
  }
}

.hero {
  position: relative;
  color: #fff;

  & .btn--instagram {
    border: none;
    // color: $pink;
    color: #A2419C;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    transition: all 0.15s ease;
    background: #fff;

    &:hover,
    &:focus,
    &:visited {
      background: #fff;      
    }

    // &:hover {
    //   transform: translateY(-1px);
    //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    // }
  }
}

  .hero__gradient {
    position: absolute;
    width: 100%;
    // height: 100%;
    height: 600px;
    // height: auto;
    // overflow: hidden;
    background-position: center;
    background-size: auto 100%;
    // background-image: url('/assets/purple-wave.svg');
    background-image: url('../images/purple-wave.svg');
  }

  .hero__intro {
    position: relative;
    height: 600px;
    display: flex;
    align-items: center;
    padding-top: 64px;
  }

  .hero__content {
    position: relative;
    padding-top: 64px;
  }

  .hero__container {
    margin: 0 auto;
    width: 100%;
    max-width: 768px;
    padding: 0 16px;
  }

    .hero__container--home {
      margin-top: -160px;      
    }

  // .hero__copy {
  //   max-width: 50%;
  // }

  .hero__link {
    color: #fff;
    font-size: 12px;

    &:hover {
      // color: hsla(0, 0%, 100%, 0.5);
      color: #fff;
      text-decoration: underline;
    }
  }

.hero-photos {
  display: none;
  pointer-events: none;
  position: absolute;
  left: 50%;
  transform: rotate(-15deg);
  transform-origin: 100% 0;
  top: 170px;
}

  .hero-photos__photo {
    position: absolute;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);

    img {
      display: block;
    }
  }

    .hero-photos__photo--handbag {
      left: 0;
      top: 153px;
    }

    .hero-photos__photo--burger {
      left: 212px;
      top: 0;
    }

    .hero-photos__photo--house {
      left: 212px;
      top: 306px;
    }

.welcome-section {
  text-align: center;
  margin: 56px 0;
}

  .welcome-section--testimonials {
    .fa-heart {
      color: $pink;
    }
  }

  .welcome-section--get-started {
    h2 {
      font-size: 32px;
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
      margin-bottom: 0.5em;
      font-size: 22px;
    }
  }

  .welcome-section__container {
    margin: 0 auto;
    width: 100%;
    max-width: 768px;
    padding: 0 16px;
  }

  .welcome-section__copy {
    max-width: 700px;
    margin: 1em auto;
  }

// .testimonials {
//   display: flex;
// }

.welcome-devices {
  max-width: 100%;
  height: auto;
}

.welcome-section--app {
  position: relative;
  padding-top: 110px;
  padding-bottom: 110px;

  .welcome-section__container {
    text-align: center;    
  }
}

.welcome-section--app:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  transform: skewY(-10deg);
  background: #f6f9fc;
}


.testimonial {
  text-align: center;
}

  .testimonial__quote {
    font-size: 14px;
  }

  .testimonial__profile {
    display: block;
    margin: 0 auto 16px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }

  .testimonial__author {
    font-size: 20px;
  }

  .testimonial__link {
    font-size: 18px;
    font-weight: bold;

    a {
      color: $dark-gray;

      &:hover {
        color: $pink;
      }
    }
  }

.welcome-footer {
  text-align: center;
  color: $gray;
  font-size: 15px;
  margin-top: 56px;
  padding-bottom: 56px;

  a {
    color: $gray;

    &:hover {
      color: $dark-gray;
    }
  }
}

.mobile {
  .welcome-footer {
    display: none;
  }
}

.footer-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

  .footer-nav__item {
    margin: 0 16px;
  }

.producthunt {
  margin: 0 auto 40px;
  padding: 0 20px;
  max-width: 750px;
  text-align: center;

  .glasshole-cat {
    width: 200px;
    height: auto;
    margin-bottom: -20px;
  }
}

.btn-app-store {
  display: inline-block;
  height: 44px;

  img {
    height: 100%;
  }
}

.btn-google-play {
  display: inline-block;
  height: 46px;

  img {
    height: 100%;
  }
}

// .btn-google-play {
//   max-width: calc((100% - 40px)/2);
// }

@media (min-width: 768px) {

  .hero-photos {
    display: block;
  }

  .hero__container {
    margin-top: 0;
  }

  .hero__copy {
    max-width: 50%;
  }

  .hero__gradient {
    background-size: 100% 100%;
  }

  .testimonials {
    display: flex;
  }

  .welcome-nav__item {
    display: flex;
  }

    .welcome-nav__item--mobile {
      display: none;    
    }

  .welcome-section--app {
    .welcome-section__container {
      display: flex;
      text-align: left;
    }
  }

  .welcome-section--app__devices {
    margin-right: 1em;
    max-width: 60%;
  }

}
